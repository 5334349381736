export default {
  setFeatureFlags({ commit }, featureFlags) {
    commit('SET_FEATURE_FLAGS', featureFlags)
  },

  setProjects({ commit }, projects) {
    commit('SET_PROJECTS', projects)
  },

  setSelectedProject({ commit }, project) {
    commit('SET_SELECTED_PROJECT', project)
  },
}
