import { ElNotification } from 'element-plus'

const notificationTime = process.env.VUE_APP_NOTIFICATION_DURATION

export default class BaseContractService {
  web3 = null
  contract = null
  dispatch = null

  constructor(web3, contract = null, mintingExperience = false, store = null) {
    this.web3 = web3
    this.contract = contract
    this.dispatch = store ? store.dispatch : null
    this.mintingExperience = mintingExperience
  }

  sendTransactionNotification(txHash, title) {
    const txURL =
      process.env.NODE_ENV === 'production'
        ? `https://etherscan.io/tx/${txHash}`
        : `https://goerli.etherscan.io/tx/${txHash}`

    ElNotification({
      title,
      dangerouslyUseHTMLString: true,
      message: `<a href="${txURL}" target="_blank">View Transaction</a>`,
      type: 'warning',
      duration: notificationTime,
    })
  }

  async getContract(contractABI, contractAddress) {
    this.contract = new this.web3.eth.Contract(contractABI, contractAddress)

    return this.contract
  }

  async sendTransaction(
    fromAddress,
    toAddress,
    gas,
    data,
    value,
    notificationTitle = 'Minting in progress.'
  ) {
    try {
      console.log('Sending transaction...')

      const transaction = {
        from: fromAddress,
        to: toAddress,
        gas,
        data,
        // Optional: use when invoking a payable function
        value,
      }
      let response

      await this.web3.eth
        .sendTransaction(transaction)
        .on('transactionHash', async (txHash) => {
          if (this.mintingExperience) {
            this.dispatch('setMintingStep', 1)
            this.dispatch('setTxHash', txHash)
          } else {
            this.sendTransactionNotification(txHash, notificationTitle)
          }
        })
        .on('receipt', (receipt) => {
          response = receipt
        })

      return response
    } catch (e) {
      console.error('An error ocurred while sending the transaction.', e)
      throw e
    }
  }
}
