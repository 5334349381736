export default {
  SET_MY_TOKENS_PAGE(state, payload) {
    state.myTokensPage = payload
  },

  RESET_MY_TOKENS(state) {
    state.myTokens = []
    state.myTokensPage = 1
  },

  PUSH_MY_TOKENS(state, payload) {
    state.myTokens.push(...payload)
  },

  SET_MY_TOKENS(state, payload) {
    state.myTokens = payload
  },
}
