import actions from './actions'
import mutations from './mutations'

const state = {
  tokensData: [],
  allCounts: 0,
  tokenAttrsCounts: {},
  paginationCursor: null,
  tokenAttrs: [],
  sortingSelected: '1',
}

export default {
  namespaced: false,
  actions,
  mutations,
  state,
}
