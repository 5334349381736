import BaseContractService from './BaseContractService'

export default class NorteContractService extends BaseContractService {
  constructor(web3, contract) {
    super(web3, contract)
  }

  async getContractPrices(hasMinted) {
    const { methods } = this.contract

    const contractPublicPrice = await methods.publicPrice().call()
    const publicPrice = this.web3.utils.fromWei(contractPublicPrice)

    let earlyCollectorPrice
    if (hasMinted === false) {
      earlyCollectorPrice = 0
    } else {
      const contractEarlyCollectorPrice = await methods
        .earlyCollectorPrice()
        .call()
      earlyCollectorPrice = this.web3.utils.fromWei(contractEarlyCollectorPrice)
    }

    const contractHolderPrice = await methods.holderPrice().call()
    const holderPrice = this.web3.utils.fromWei(contractHolderPrice)

    let primeHolderPrice
    if (hasMinted === false) {
      primeHolderPrice = 0
    } else {
      primeHolderPrice = await methods.primeHolderPrice().call()
      primeHolderPrice = this.web3.utils.fromWei(primeHolderPrice)
    }

    return {
      publicPrice,
      earlyCollectorPrice,
      primeHolderPrice,
      holderPrice,
    }
  }

  async getMintedCombinations(walletAddress) {
    const mintedCombinations = {}

    for (let i = 1; i <= 4; i++) {
      for (let j = 1; j <= 6; j++) {
        const combination = `${i}${j}`

        const mintedCombination = await this.contract.methods
          .getIsMintedCombination(walletAddress, combination)
          .call()

        if (mintedCombination === true) {
          mintedCombinations[combination] = mintedCombination
        }
      }
    }

    return mintedCombinations
  }

  async readContract(walletAddress) {
    const { methods } = this.contract

    const supplyLeft = await methods.supplyLeft().call()
    const isPaused = await methods.paused().call()
    const isPublicMintingPaused = await methods.publicMintingPaused().call()
    const balanceOf = await methods.balanceOf(walletAddress).call()
    const hasMinted = await methods.getHasMinted(walletAddress).call()

    // Getting prices from contract
    const { publicPrice, earlyCollectorPrice, holderPrice, primeHolderPrice } =
      await this.getContractPrices(hasMinted)

    const mintedCombinations = await this.getMintedCombinations(walletAddress)

    return {
      supplyLeft,
      isPaused,
      isPublicMintingPaused,
      balanceOf,
      mintedCombinations,
      hasMinted,
      publicPrice,
      earlyCollectorPrice,
      holderPrice,
      primeHolderPrice,
    }
  }

  async cryptoArteEarlyCollectorMint(params) {
    const {
      mode,
      style,
      var1,
      var2,
      background,
      showAddress,
      price,
      contractAddress,
      signature,
      walletAddress,
    } = params

    try {
      console.log('Minting EC')

      const priceInWei = this.web3.utils.toWei(price.toString(), 'ether')
      const gas = 325000
      const data = this.contract.methods
        .cryptoArteEarlyCollectorMint(
          signature,
          mode,
          style,
          var1,
          var2,
          background,
          showAddress
        )
        .encodeABI()

      const response = await this.sendTransaction(
        walletAddress,
        contractAddress,
        gas,
        data,
        priceInWei
      )

      return response
    } catch (e) {
      console.error(
        'An error ocurred while doing the early collector minting.',
        e
      )
      throw e
    }
  }

  async cryptoArteHolderMint(params) {
    const {
      mode,
      style,
      var1,
      var2,
      background,
      showAddress,
      price,
      contractAddress,
      signature,
      walletAddress,
    } = params

    try {
      console.log('Minting Holder')

      const priceInWei = this.web3.utils.toWei(price.toString(), 'ether')
      const gas = 325000
      const data = this.contract.methods
        .cryptoArteHolderMint(
          signature,
          mode,
          style,
          var1,
          var2,
          background,
          showAddress
        )
        .encodeABI()

      const response = await this.sendTransaction(
        walletAddress,
        contractAddress,
        gas,
        data,
        priceInWei
      )

      return response
    } catch (e) {
      console.error(
        'An error ocurred while doing the CriptoArte holder minting.',
        e
      )
      throw e
    }
  }

  async cryptoArtePrimeHolderMint(params) {
    const {
      mode,
      style,
      var1,
      var2,
      background,
      showAddress,
      price,
      contractAddress,
      signature,
      walletAddress,
    } = params

    try {
      console.log('Minting Prime')

      const priceInWei = this.web3.utils.toWei(price.toString(), 'ether')
      const gas = 325000
      const data = this.contract.methods
        .cryptoArtePrimeHolderMint(
          signature,
          mode,
          style,
          var1,
          var2,
          background,
          showAddress
        )
        .encodeABI()

      const response = await this.sendTransaction(
        walletAddress,
        contractAddress,
        gas,
        data,
        priceInWei
      )

      return response
    } catch (e) {
      console.error(
        'An error ocurred while doing the CriptoArte prime holder minting.',
        e
      )
      throw e
    }
  }

  async publicMint(params) {
    const {
      mode,
      style,
      var1,
      var2,
      background,
      showAddress,
      price,
      contractAddress,
      walletAddress,
    } = params

    try {
      console.log('Minting Public')

      const priceInWei = this.web3.utils.toWei(price.toString(), 'ether')
      const gas = 325000
      const data = this.contract.methods
        .publicMint(mode, style, var1, var2, background, showAddress)
        .encodeABI() // Passing mode and style as strings

      const response = await this.sendTransaction(
        walletAddress,
        contractAddress,
        gas,
        data,
        priceInWei
      )

      return response
    } catch (e) {
      console.error('An error ocurred while doing the public minting.', e)
      throw e
    }
  }

  async updateMint(params) {
    const {
      tokenID,
      var1,
      var2,
      background,
      showAddress,
      contractAddress,
      walletAddress,
    } = params

    try {
      console.log('Updating token')

      const gas = 55000
      const data = this.contract.methods
        .updateMint(tokenID, var1, var2, background, showAddress)
        .encodeABI()

      const response = await this.sendTransaction(
        walletAddress,
        contractAddress,
        gas,
        data,
        null,
        'Update in progress.'
      )

      return response
    } catch (e) {
      console.error('An error ocurred while doing the update.', e)
      throw e
    }
  }
}
