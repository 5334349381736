import BaseContractService from './BaseContractService'

export default class EscalerasContractService extends BaseContractService {
  constructor(web3, contract, mintingExperience = false, store = null) {
    super(web3, contract, mintingExperience, store)
  }

  async getContractPublicPrice() {
    const { methods } = this.contract

    const contractPublicPrice = await methods.publicPrice().call()
    const publicPrice = this.web3.utils.fromWei(contractPublicPrice)

    return publicPrice
  }

  async getContractAllowListPrice() {
    const { methods } = this.contract

    const contractAllowListPrice = await methods.allowListPrice().call()
    const allowListPrice = this.web3.utils.fromWei(contractAllowListPrice)

    return allowListPrice
  }

  async readContract(walletAddress) {
    const { methods } = this.contract

    const supplyLeft = await methods.supplyLeft().call()
    const isPaused = await methods.paused().call()
    const balanceOf = await methods.balanceOf(walletAddress).call()
    const publicPrice = await this.getContractPublicPrice()
    const allowListPrice = await this.getContractAllowListPrice()
    const allowListMinting = await methods.allowListMinting().call()
    const publicMinting = await methods.publicMinting().call()

    return {
      supplyLeft,
      isPaused,
      balanceOf,
      publicPrice,
      allowListPrice,
      allowListMinting,
      publicMinting,
    }
  }

  async publicMint(params) {
    const { price, contractAddress, walletAddress } = params

    try {
      console.log('Minting Public')

      const priceInWei = this.web3.utils.toWei(price.toString(), 'ether')
      const gas = 325000
      const data = this.contract.methods.publicMint().encodeABI()

      const response = await this.sendTransaction(
        walletAddress,
        contractAddress,
        gas,
        data,
        priceInWei
      )

      return response
    } catch (e) {
      console.error('An error ocurred while doing the public minting.', e)
      throw e
    }
  }

  async allowListMint(params) {
    const { contractAddress, walletAddress, price, signature } = params

    try {
      const priceInWei = this.web3.utils.toWei(price.toString(), 'ether')
      const gas = 325000
      const data = this.contract.methods.allowListMint(signature).encodeABI()

      const response = await this.sendTransaction(
        walletAddress,
        contractAddress,
        gas,
        data,
        priceInWei
      )

      return response
    } catch (e) {
      console.error('An error ocurred while allowList minting.', e)
      throw e
    }
  }
}
