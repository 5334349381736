export default {
  SET_FEATURE_FLAGS(state, payload) {
    const expDate = new Date()

    // Sets the expiration date of the feature flags stored in vuex to 5 minutes.
    expDate.setMinutes(expDate.getMinutes() + 5)

    state.featureFlags = payload
    state.featureFlagsExpirationDate = new Date(expDate)
  },

  SET_PROJECTS(state, payload) {
    const expDate = new Date()

    // Sets the expiration date of the feature flags stored in vuex to 5 minutes.
    expDate.setMinutes(expDate.getMinutes() + 5)

    state.projects = payload
    state.projectsExpirationDate = new Date(expDate)
  },

  SET_SELECTED_PROJECT(state, payload) {
    state.selectedProject = payload
  },
}
