import BaseContractService from './BaseContractService'

export default class FrasesContractService extends BaseContractService {
  constructor(web3, contract) {
    super(web3, contract)
  }

  async publicPrice(web3) {
    try {
      const price = await this.contract.methods.publicPrice().call()

      return web3.utils?.fromWei(price, 'ether')
    } catch (e) {
      console.error('An error ocurred getting publicPrice.', e)
      throw e
    }
  }

  async paused() {
    try {
      const paused = await this.contract.methods.paused().call()

      return paused
    } catch (e) {
      console.error('An error ocurred getting contract pause status.', e)
      throw e
    }
  }

  async claimedPalabrasTokens(tokenID) {
    try {
      const claimedPalabrasTokenIds = await this.contract.methods
        .claimedPalabrasTokens(tokenID)
        .call()

      return claimedPalabrasTokenIds
    } catch (e) {
      console.error('An error ocurred getting claimedPalabrasTokens.', e)
      throw e
    }
  }

  async publicMint(params) {
    const {
      contractAddress,
      walletAddress,
      palabrasTokenIds,
      artworkId,
      signature,
      mintPrice,
    } = params

    try {
      const data = this.contract.methods
        .publicMint(palabrasTokenIds, artworkId, mintPrice, signature)
        .encodeABI()

      const response = await this.sendTransaction(
        walletAddress,
        contractAddress,
        null,
        data,
        mintPrice
      )

      return response
    } catch (e) {
      console.error('An error ocurred while public minting.', e)
      throw e
    }
  }
}
