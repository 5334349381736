<template>
  <div>
    <div>
      <el-container>
        <el-header class="header">
          <div class="logo-container">
            <Logo :logo="require('../../assets/images/logo-studiouno.png')" />
          </div>
          <div class="menu-container">
            <div class="desktop">
              <el-menu
                :default-active="activeIndex"
                mode="horizontal"
                @open="handleOpen"
                @close="handleClose"
                @select="handleSelect"
              >
                <el-menu-item index="/about" @click="goToPath('/about')">
                  About
                </el-menu-item>
                <el-menu-item
                  index="/your-tokens"
                  @click="goToPath('/your-tokens')"
                >
                  Your tokens
                </el-menu-item>
                <el-menu-item index="/artists" @click="goToPath('/artists')">
                  Artists
                </el-menu-item>
                <el-menu-item
                  index="/galleries"
                  @click="goToPath('/galleries')"
                >
                  Galleries
                </el-menu-item>
                <el-sub-menu index="1">
                  <template #title>Projects</template>
                  <el-menu-item
                    v-for="projectData in projectsData"
                    :key="projectData.projectID"
                    :index="`/${projectData.projectSlug}`"
                    @click="goToPath(`/${projectData.projectSlug}`)"
                  >
                    {{ projectData.name }}
                  </el-menu-item>
                </el-sub-menu>
              </el-menu>
              <div class="wallet-container">
                <Wallet />
              </div>
            </div>
            <div class="mobile">
              <el-icon class="fold-icon">
                <fold @click="drawer = true" />
              </el-icon>
            </div>
          </div>
        </el-header>
        <el-main class="main" id="main">
          <NetworkAlert />
          <notifications width="400px" :duration="300000" />
          <slot />
        </el-main>
        <el-footer class="footer">
          <el-row>
            <el-col class="s1-social-links">
              <el-link
                href="https://discord.com/invite/kwbRuwf"
                target="_blank"
                :underline="false"
              >
                <img src="../../assets/icons/discord-icon.svg" />
              </el-link>
              <el-link
                href="https://twitter.com/StudioUnoNFT"
                target="_blank"
                :underline="false"
              >
                <img src="../../assets/icons/twitter-icon.svg" />
              </el-link>
            </el-col>
            <el-col style="margin-bottom: 10px">
              ©{{ new Date().getFullYear() }} — StudioUno, LLC
            </el-col>
          </el-row>
        </el-footer>
      </el-container>
    </div>
    <el-drawer
      v-model="drawer"
      direction="rtl"
      size="60%"
      :before-close="handleClose"
      :with-header="false"
    >
      <el-menu
        :default-active="activeIndex"
        :default-openeds="['1']"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        @select="handleSelect"
      >
        <el-menu-item
          index="/about"
          class="mobile-menu-item"
          @click="goToPath('/about')"
        >
          About
        </el-menu-item>
        <el-menu-item
          index="/your-tokens"
          class="mobile-menu-item"
          @click="goToPath('/your-tokens')"
        >
          Your tokens
        </el-menu-item>
        <el-menu-item
          index="/artists"
          class="mobile-menu-item"
          @click="goToPath('/artists')"
        >
          Artists
        </el-menu-item>
        <el-menu-item
          index="/galleries"
          class="mobile-menu-item"
          @click="goToPath('/galleries')"
        >
          Galleries
        </el-menu-item>
        <el-sub-menu index="1">
          <template #title>
            <span><b>Projects</b></span>
          </template>
          <el-menu-item
            v-for="projectData in projectsData"
            :key="projectData.projectID"
            :index="`/${projectData.projectSlug}`"
            @click="goToPath(`/${projectData.projectSlug}`)"
          >
            {{ projectData.name }}
          </el-menu-item>
        </el-sub-menu>
        <el-menu-item index="4" class="dock-right">
          <div class="wallet-mobile-container">
            <Wallet />
          </div>
        </el-menu-item>
      </el-menu>
    </el-drawer>
  </div>
</template>

<script>
  import { defineComponent, ref, computed, onBeforeMount } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { Fold } from '@element-plus/icons'

  import Logo from '../logo'
  import NetworkAlert from '../network-alert'
  import Wallet from '../wallet'
  import { isFeatureFlagEnabled } from '@/utils/utils'
  import { useStore } from 'vuex'

  export default defineComponent({
    name: 'Layout',
    components: {
      Fold,
      Logo,
      NetworkAlert,
      Wallet,
    },

    setup() {
      const route = useRoute()
      const router = useRouter()

      const drawer = ref(false)
      const activeIndex = ref(window.location.pathname)
      const isTestProjectEnabled = ref(false)

      const goToPath = (path) => {
        if (route.path !== path) {
          router.push({ path })
        }
      }
      const store = useStore()
      const {
        dispatch,
        state: { root },
      } = store

      const projectsData = computed(() => root.projects)

      const handleClose = (done) => {
        if (typeof done === 'function') {
          done()
        }
      }

      const handleOpen = () => {}

      const handleSelect = (index) => {
        if (index === '4') {
          drawer.value = true
        } else {
          drawer.value = false
        }
      }

      onBeforeMount(async () => {
        dispatch('setMintingStep', 0)
        isTestProjectEnabled.value = isFeatureFlagEnabled('testProjectEnabled')
      })

      return {
        drawer,
        activeIndex,
        isTestProjectEnabled,
        projectsData,
        goToPath,
        handleClose,
        handleOpen,
        handleSelect,
      }
    },
  })
</script>

<style>
  body,
  html {
    margin: 0;
    overflow: hidden;
  }

  .el-drawer__body {
    padding: 0 !important;
    text-align: left;
  }

  .el-drawer__body .el-menu-item.dock-right {
    padding: 0 !important;
    text-align: center;
  }
</style>
<style scoped>
  .header {
    height: 64px;
    padding: 0;
    border-bottom: 1px solid #e6e6e6;
  }

  .main {
    height: calc(100vh - 180px);
    padding: 6px;
  }

  .footer {
    margin-top: 30px;
    background: #d5ffff;
    height: 100%;
    font-size: 0.75em;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 900;
  }

  .logo-container {
    width: 200px;
    justify-content: center;
    align-items: center;
    height: 60px;
    line-height: 56px;
    margin: 0;
    padding: 0 20px;
    list-style: none;
    cursor: pointer;
    box-sizing: border-box;
    white-space: nowrap;
    float: left;
  }

  .logo-container * {
    vertical-align: middle;
  }

  .menu-container {
    width: calc(100% - 210px);
    height: 100%;
    float: right;
    display: flex;
  }

  .menu-container .el-menu {
    width: 100%;
    justify-content: right;
  }

  .menu-container .el-menu--horizontal {
    border-bottom: 0;
  }

  .fold-icon {
    width: 2em;
    height: 2em;
    vertical-align: middle;
  }

  .fold-icon svg {
    width: 2em;
    height: 2em;
  }

  .desktop {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .mobile {
    float: right;
    display: none;
  }

  .wallet-container {
    margin: 0 40px;
  }

  .wallet-mobile-container {
    width: 100%;
  }

  .s1-social-links img {
    width: 25px;
    height: auto;
  }

  .s1-social-links a {
    margin: 10px 5px 10px 15px;
  }

  .s1-social-links {
    margin-top: 10px;
  }

  .mobile-menu-item {
    font-weight: bold;
  }

  /* Specific styles for Mobile Devices */
  @media screen and (max-width: 575px) {
    .logo-container {
      width: 80px;
    }

    .menu-container {
      width: 45px;
    }

    .main {
      padding: 6px;
    }

    .desktop {
      display: none;
    }

    .mobile {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>
