import actions from './actions'
import mutations from './mutations'

const state = {
  mode: '1',
  style: '1',
  var1: '42',
  var2: '86',
  background: true,
  showAddress: true,
  iteration: 1,
  loading: false,
  renderingCanvas: false,
  editMode: false,
}

export default {
  namespaced: false,
  actions,
  mutations,
  state,
}
