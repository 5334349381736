export default {
  resetToolPanel({ commit }) {
    commit('RESET_TOOL_PANEL')
  },

  setMode({ commit }, mode) {
    commit('SET_MODE', mode)
  },

  setStyle({ commit }, style) {
    commit('SET_STYLE', style)
  },

  setVar1({ commit }, var1) {
    commit('SET_VAR_1', var1)
  },

  setVar2({ commit }, var2) {
    commit('SET_VAR_2', var2)
  },

  setBackground({ commit }, background) {
    commit('SET_BACKGROUND', background)
  },

  setShowAddress({ commit }, showAddress) {
    commit('SET_SHOW_ADDRESS', showAddress)
  },

  setIteration({ commit }, iteration) {
    commit('SET_ITERATION', iteration)
  },

  setLoading({ commit }, loading) {
    commit('SET_LOADING', loading)
  },

  setRenderingCanvas({ commit }, renderingCanvas) {
    commit('SET_RENDERING_CANVAS', renderingCanvas)
  },

  setEditMode({ commit }, editMode) {
    commit('SET_EDIT_MODE', editMode)
  },
}
