export default {
  setMyTokensPage({ commit }, page) {
    commit('SET_MY_TOKENS_PAGE', page)
  },

  resetMyTokens({ commit }) {
    commit('RESET_MY_TOKENS')
  },

  pushMyTokens({ commit }, tokens) {
    commit('PUSH_MY_TOKENS', tokens)
  },

  setMyTokens({ commit }, tokens) {
    commit('SET_MY_TOKENS', tokens)
  },
}
