export default {
  SET_MINTING_STEP(state, payload) {
    state.mintingStep = payload
  },
  SET_TX_HASH(state, payload) {
    state.txHash = payload
  },
  SET_MINTED_QTY(state, payload) {
    state.mintedQty = payload
  },
  SET_TX_ERROR(state, payload) {
    state.txError = payload
  },
}
