import actions from './actions'
import mutations from './mutations'

const state = {
  mintingStep: 0,
  txHash: '',
  mintedQty: 0,
  txError: '',
}

export default {
  namespaced: false,
  actions,
  mutations,
  state,
}
