export default {
  SET_FRASES_TOKENS_SELECTED(state, payload) {
    const artworkTokensSelected = state.frasesTokensSelected.find(
      (el) => el.artworkID == payload.artworkID
    )
    if (artworkTokensSelected) {
      artworkTokensSelected.tokenIds = payload.tokenIds
    } else {
      state.frasesTokensSelected.push(payload)
    }
  },

  SET_ALLOW_MINT(state, payload) {
    const artworkTokensSelected = state.frasesTokensSelected.find(
      (el) => el.artworkID == payload.artworkID
    )
    if (artworkTokensSelected) {
      artworkTokensSelected.allowMint = payload.allowMint
    } else {
      state.frasesTokensSelected.push(payload)
    }
  },

  RESET_FRASES_TOKENS_SELECTED(state) {
    state.tokenAttrsCounts = []
  },
}
