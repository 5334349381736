export default {
  setAllCounts({ commit }, counts) {
    commit('SET_ALL_COUNTS', counts)
  },

  setTokenAttrsCounts({ commit }, tokenAttrsCounts) {
    commit('SET_TOKEN_ATTRS_COUNTS', tokenAttrsCounts)
  },

  setPaginationCursor({ commit }, paginationCursor) {
    commit('SET_PAGINATION_CURSOR', paginationCursor)
  },

  setSortingSelected({ commit }, sortingSelected) {
    commit('SET_SORTING_SELECTED', sortingSelected)
  },

  setTokenAttrs({ commit }, tokenAttrs) {
    commit('SET_TOKEN_ATTRS', tokenAttrs)
  },

  pushTokenAttrs({ commit }, tokenAttrs) {
    commit('PUSH_TOKEN_ATTRS', tokenAttrs)
  },

  removeTokenAttr({ commit }, tokenAttrName) {
    commit('REMOVE_TOKEN_ATTR', tokenAttrName)
  },

  resetTokenAttrs({ commit }) {
    commit('RESET_TOKEN_ATTRS')
  },

  resetTokensData({ commit }) {
    commit('RESET_TOKENS_DATA')
  },

  pushTokenData({ commit }, tokenData) {
    commit('PUSH_TOKEN_DATA', tokenData)
  },

  setTokenData({ commit }, tokenData) {
    commit('SET_TOKEN_DATA', tokenData)
  },
}
