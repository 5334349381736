export default {
  RESET_TOOL_PANEL(state) {
    state.mode = '1'
    state.style = '1'
    state.var1 = '1'
    state.var2 = '1'
    state.background = true
    state.showAddress = true
  },

  SET_MODE(state, payload) {
    state.mode = payload
  },

  SET_STYLE(state, payload) {
    state.style = payload
  },

  SET_VAR_1(state, payload) {
    state.var1 = payload
  },

  SET_VAR_2(state, payload) {
    state.var2 = payload
  },

  SET_BACKGROUND(state, payload) {
    state.background = payload
  },

  SET_SHOW_ADDRESS(state, payload) {
    state.showAddress = payload
  },

  SET_ITERATION(state, payload) {
    state.iteration = payload
  },

  SET_LOADING(state, payload) {
    state.loading = payload
  },

  SET_RENDERING_CANVAS(state, payload) {
    state.renderingCanvas = payload
  },

  SET_EDIT_MODE(state, payload) {
    state.editMode = payload
  },
}
