import { createRouter, createWebHistory } from 'vue-router'

import { HOME_ROUTE } from '@/constants/routes'

const routes = [
  {
    path: HOME_ROUTE,
    component: () => import('@/views/Home'),
  },
  {
    path: '/login',
    component: () => import('@/views/Login'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About'),
  },
  {
    path: '/norte',
    name: 'Norte Project',
    component: () => import('@/views/Norte'),
  },
  {
    path: '/rotor',
    name: 'Rotor Project',
    component: () => import('@/views/Rotor'),
  },
  {
    path: '/galleries',
    name: 'Galleries',
    component: () => import('@/views/Galleries'),
  },
  {
    path: '/artists',
    name: 'Artists',
    component: () => import('@/views/Artists'),
  },
  {
    path: '/palabras',
    name: 'Palabras',
    component: () => import('@/views/Palabras'),
  },
  {
    path: '/frases',
    name: 'Frases',
    component: () => import('@/views/Frases'),
  },
  {
    path: '/base-gen-art',
    name: 'BaseGenArt',
    component: () => import('@/views/BaseGenArt'),
  },
  {
    path: '/escaleras',
    name: 'Escaleras',
    component: () => import('@/views/Escaleras'),
  },
  {
    path: '/tokens/:project_slug/:token_id',
    component: () => import('@/views/Token'),
  },
  {
    path: '/artists/:artist_slug',
    component: () => import('@/views/Artist'),
  },
  {
    path: '/tokens/:project_slug',
    component: () => import('@/views/Gallery'),
    meta: {
      scrollPos: {
        top: 0,
        left: 0,
      },
    },
  },
  {
    path: '/your-tokens', // change to '/your-tokens'
    component: () => import('@/views/GalleryYourTokens'),
    meta: {
      scrollPos: {
        top: 0,
        left: 0,
      },
    },
  },
  {
    path: '/tokens/:project_slug/:token_id/edit',
    component: () => import('@/views/UpdateToken'),
  },
  {
    path: '/test-project',
    name: 'Test Project',
    component: () => import('@/views/TestProject'),
  },
]

const scrollBehavior = (to, from, savedPosition) => {
  return savedPosition || to.meta?.scrollPos || { top: 0, left: 0 }
}

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior,
})

router.beforeEach((to, from, next) => {
  const main = document.getElementById('main')

  from.meta?.scrollPos && (from.meta.scrollPos.top = main.scrollTop)
  return next()
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')

  if (authRequired && !loggedIn && process.env.VUE_APP_ENABLE_LOGIN == 'true') {
    return next({
      path: '/login',
      query: { returnUrl: to.path },
    })
  }

  next()
})

router.afterEach((to) => {
  if (
    process.env.VUE_APP_FIREBASE_MEASUREMENT_ID !== '{firebaseMeasurementId}'
  ) {
    // eslint-disable-next-line no-undef
    gtag('config', process.env.VUE_APP_FIREBASE_MEASUREMENT_ID, {
      page_path: to.fullPath,
      send_page_view: true,
    })
  }
})

router.afterEach((to) => {
  const main = document.getElementById('main')

  if (to.meta?.scrollPos?.top) {
    setTimeout(() => {
      main.scrollTop = to.meta.scrollPos.top
    }, 1)
  }
})

export default router
