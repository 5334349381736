<template>
  <div class="app-container" v-loading="loading">
    <Layout v-if="!loading">
      <router-view />
    </Layout>
  </div>
</template>

<script>
  import { computed, ref, onBeforeMount } from 'vue'
  import { useStore } from 'vuex'

  import Layout from './components/layout/index.vue'

  import { getAllFeatureFlags } from '@/utils/utils'
  import { getProjectsData } from '@/services/ProjectService'

  export default {
    name: 'App',
    components: {
      Layout,
    },

    setup() {
      // Hooks
      const store = useStore()
      const {
        dispatch,
        state: { root },
      } = store

      const featureFlagsExpirationDate = computed(
        () => root.featureFlagsExpirationDate
      )

      const projectsExpirationDate = computed(() => root.projectsExpirationDate)

      const loading = ref(false)

      const initializeFeatureFlags = async () => {
        const now = new Date()
        const expirationDate = new Date(featureFlagsExpirationDate.value)

        if (expirationDate < now) {
          loading.value = true
          const featureFlags = await getAllFeatureFlags()

          dispatch('setFeatureFlags', featureFlags)

          setTimeout(() => {
            loading.value = false
          }, 2)
        }
      }

      const initializeProjects = async () => {
        const now = new Date()
        const expirationDate = new Date(projectsExpirationDate.value)

        if (expirationDate < now) {
          loading.value = true
          const projectsResponse = await getProjectsData()
          const projects = projectsResponse.response

          dispatch('setProjects', projects)

          setTimeout(() => {
            loading.value = false
          }, 2)
        }
      }

      onBeforeMount(async () => {
        await initializeProjects()
        if (process.env.VUE_APP_ENABLE_FEATURE_FLAGS === 'true') {
          await initializeFeatureFlags()
        } else {
          dispatch('setFeatureFlags', [])
        }
      })

      return { loading }
    },
  }
</script>
<style lang="less">
  .el-main {
    padding: 10px;
  }

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  .app-container {
    height: 100vh;
  }
</style>
