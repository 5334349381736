export default {
  setFrasesTokensSelected({ commit }, counts) {
    commit('SET_FRASES_TOKENS_SELECTED', counts)
  },

  setAllowMint({ commit }, counts) {
    commit('SET_ALLOW_MINT', counts)
  },

  resetFrasesTokensSelected({ commit }) {
    commit('RESET_FRASES_TOKENS_SELECTED')
  },
}
