export default {
  RESET_WALLET_INFORMATION(state) {
    state.balance = null
    state.networkId = null
    state.walletAddress = null
    state.whitelistedEarly = null
    state.whitelistedHolder = null
    state.whitelistedPrime = null
  },

  SET_WALLET_INFORMATION(state, payload) {
    const {
      balance,
      whitelistedEarly,
      whitelistedHolder,
      whitelistedPrime,
      networkId,
      walletAddress,
    } = payload

    state.balance = balance
    state.whitelistedEarly = whitelistedEarly
    state.whitelistedHolder = whitelistedHolder
    state.whitelistedPrime = whitelistedPrime
    state.networkId = networkId
    state.walletAddress = walletAddress
  },

  SET_WALLET_ADDRESS(state, payload) {
    state.walletAddress = payload
  },

  SET_NOTIFY_WHITELISTED_SETTINGS(state, payload) {
    state.notifyWhitelistedSettings = payload
  },
}
