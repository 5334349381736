import { web3WalletClient } from '@/web3'
import { client } from '@/axios'
import { db } from '@/firebase'
import { WHITELIST_COLLECTION } from '@/constants/collections'

const addressesRef = db.collection(WHITELIST_COLLECTION)

const checkIfWhitelisted = async (address) => {
  const params = { address }
  const { data } = await client.get('isWhitelisted', { params })
  return data.result
}

export const getSignature = async (address, type) => {
  let signature = ''
  let errorFire
  try {
    const snapshot = await addressesRef
      .where('address', '==', address.toLowerCase())
      .where('type', '==', type)
      .get()

    if (snapshot.empty) {
      console.log('No such document!')
    } else {
      snapshot.forEach((doc) => {
        signature = doc.data().signature
      })
    }
  } catch (e) {
    console.log('[ !!! ] Error retrieving signature: ', e)
    errorFire = e
  }

  return { signature, errorFire }
}

export const getUserInformation = async (provider) => {
  const web3 = web3WalletClient(provider)
  const { eth, utils } = web3

  const accounts = await eth.getAccounts()
  const address = accounts[0]
  const networkId = await eth.net.getId()
  const balanceInWei = await eth.getBalance(address)
  let balance = utils.fromWei(balanceInWei)
  balance = Math.round((Number(balance) + Number.EPSILON) * 100) / 100

  const isWhitelisted = await checkIfWhitelisted(address)

  let whitelistedEarly = false
  let whitelistedHolder = false
  let whitelistedPrime = false

  for (let i = 0; i < isWhitelisted.length; i++) {
    if (isWhitelisted[i] == 'early') {
      whitelistedEarly = true
    } else if (isWhitelisted[i] == 'holder') {
      whitelistedHolder = true
    } else if (isWhitelisted[i] == 'prime') {
      whitelistedPrime = true
    }
  }

  return {
    balance,
    whitelistedEarly,
    whitelistedHolder,
    whitelistedPrime,
    networkId,
    walletAddress: address,
  }
}
