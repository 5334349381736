import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

import counter from './counter'
import user from './user'
import toolPanel from './tool-panel'
import contract from './contract'
import gallery from './gallery'
import myGallery from './my-gallery'
import root from './root'
import frases from './frases'
import mintingExperience from './minting-experience'

export default createStore({
  modules: {
    counter,
    user,
    toolPanel,
    contractState: contract,
    gallery: gallery,
    myGallery: myGallery,
    root: root,
    frases: frases,
    mintingExperience: mintingExperience,
  },
  plugins: [
    new VuexPersistence({
      modules: ['user', 'toolPanel', 'gallery', 'myGallery', 'root'],
    }).plugin,
  ],
})
