import BaseContractService from './BaseContractService'

export default class PalabrasContractService extends BaseContractService {
  constructor(web3, contract, mintingExperience = false, store = null) {
    super(web3, contract, mintingExperience, store)
  }

  async getCurrentRound() {
    try {
      const roundNumber = await this.contract.methods
        .currentRoundNumber()
        .call()

      return roundNumber
    } catch (e) {
      console.error('An error ocurred getting currentRound.', e)
      throw e
    }
  }

  async publicPrice(web3) {
    try {
      const price = await this.contract.methods.publicPrice().call()

      return web3.utils?.fromWei(price, 'ether')
    } catch (e) {
      console.error('An error ocurred getting publicPrice.', e)
      throw e
    }
  }

  async paused() {
    try {
      const paused = await this.contract.methods.paused().call()

      return paused
    } catch (e) {
      console.error('An error ocurred getting contract pause status.', e)
      throw e
    }
  }

  async maxPublicMints() {
    try {
      const max = await this.contract.methods.maxPublicMints().call()

      return max
    } catch (e) {
      console.error('An error ocurred getting maxPublicMints.', e)
      throw e
    }
  }

  async allowListMinting() {
    try {
      const max = await this.contract.methods.allowListMinting().call()

      return max
    } catch (e) {
      console.error('An error ocurred getting allowListMinting.', e)
      throw e
    }
  }

  async publicMinting() {
    try {
      const max = await this.contract.methods.publicMinting().call()

      return max
    } catch (e) {
      console.error('An error ocurred getting publicMinting.', e)
      throw e
    }
  }

  async publicMint(params) {
    const { contractAddress, walletAddress, palabra, price, quantityToMint } =
      params

    try {
      const priceInWei = this.web3.utils.toWei(price.toString(), 'ether')
      const { word, roundNumber, quantityAllowed, signature } = palabra
      const data = this.contract.methods
        .publicMint(
          word,
          roundNumber,
          quantityToMint,
          quantityAllowed,
          signature
        )
        .encodeABI()

      const response = await this.sendTransaction(
        walletAddress,
        contractAddress,
        null,
        data,
        priceInWei
      )

      return response
    } catch (e) {
      console.error('An error ocurred while public minting.', e)
      throw e
    }
  }

  async allowListMint(params) {
    const {
      contractAddress,
      walletAddress,
      palabra,
      price,
      quantityToMint,
      signature,
    } = params

    try {
      const priceInWei = this.web3.utils.toWei(price.toString(), 'ether')
      const { word, roundNumber, quantityAllowed } = palabra
      const data = this.contract.methods
        .allowListMint(
          word,
          roundNumber,
          quantityToMint,
          quantityAllowed,
          signature
        )
        .encodeABI()

      const response = await this.sendTransaction(
        walletAddress,
        contractAddress,
        null,
        data,
        priceInWei
      )

      return response
    } catch (e) {
      console.error('An error ocurred while allowList minting.', e)
      throw e
    }
  }

  async premiumMint(params) {
    const { contractAddress, walletAddress, palabra } = params

    try {
      const { word, signature, price } = palabra
      const data = this.contract.methods
        .premiumMint(word, price, signature)
        .encodeABI()

      const response = await this.sendTransaction(
        walletAddress,
        contractAddress,
        null,
        data,
        price
      )

      return response
    } catch (e) {
      console.error('An error ocurred while premium minting.', e)
      throw e
    }
  }

  async premiumRequestedMint(params) {
    const { contractAddress, walletAddress, palabra } = params

    try {
      const { word, signature, price } = palabra
      const data = this.contract.methods
        .premiumRequestedMint(word, price, signature)
        .encodeABI()

      const response = await this.sendTransaction(
        walletAddress,
        contractAddress,
        null,
        data,
        price
      )

      return response
    } catch (e) {
      console.error('An error ocurred while premium requested minting.', e)
      throw e
    }
  }

  async claimMint(params) {
    const { contractAddress, walletAddress, palabra, tokenId } = params

    try {
      const { word, roundNumber, collectionAddress, signature } = palabra
      const data = this.contract.methods
        .claim(tokenId, collectionAddress, word, roundNumber, signature)
        .encodeABI()

      const response = await this.sendTransaction(
        walletAddress,
        contractAddress,
        null,
        data,
        null
      )

      return response
    } catch (e) {
      console.error('An error ocurred while claim minting.', e)
      throw e
    }
  }

  async mintedCounts(signedPalabra) {
    const { word, roundNumber } = signedPalabra
    try {
      return await this.contract.methods.mintedCounts(word, roundNumber).call()
    } catch (e) {
      console.error('An error ocurred while getting mintedCounts.', e)
      throw e
    }
  }

  async mintedWord(signedPalabra) {
    const { word } = signedPalabra
    try {
      return await this.contract.methods.mintedWords(word).call()
    } catch (e) {
      console.error('An error ocurred while getting mintedWords.', e)
      throw e
    }
  }

  async getClaimableTokens(walletTokenIds, signedPalabra) {
    const { word, roundNumber, collectionAddress } = signedPalabra
    let totalClaimed = 0
    let tokenId = 0
    try {
      for (let i = 0; i < walletTokenIds?.length; i++) {
        const hashedKey = this.web3.utils.soliditySha3(
          walletTokenIds[i],
          word,
          collectionAddress,
          roundNumber
        )
        let claimedToken = await this.contract.methods
          .claimedTokenIds(hashedKey)
          .call()
        if (claimedToken) {
          totalClaimed++
        } else {
          tokenId = walletTokenIds[i]
        }
      }
      return {
        totalClaimed: totalClaimed,
        tokenId: tokenId,
      }
    } catch (e) {
      console.error('An error ocurred while getting claimable tokens.', e)
      throw e
    }
  }
}
