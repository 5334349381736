import actions from './actions'
import mutations from './mutations'

const state = {
  balance: null,
  networkId: null,
  walletAddress: null,
  whitelistedEarly: null,
  whitelistedHolder: null,
  whitelistedPrime: null,
  notifyWhitelistedSettings: null,
}

export default {
  namespaced: false,
  actions,
  mutations,
  state,
}
