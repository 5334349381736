import { db } from '@/firebase'
import { PROJECT_COLLECTION } from '@/constants/collections'
import store from '../store/index'
import NorteContractService from './NorteContractService'
import TestProjectContractService from './TestProjectContractService'
import PalabrasContractService from './PalabrasContractService'
import FrasesContractService from './FrasesContractService'
import BaseGenArtContractService from './BaseGenArtContractService'
import EscalerasContractService from './EscalerasContractService'

const projectsRef = db.collection(PROJECT_COLLECTION)

export async function getProjectsData() {
  let response
  let error
  try {
    const snapshot = await projectsRef.orderBy('projectID', 'desc').get()
    response = snapshot.docs.map((doc) => doc.data())
  } catch (e) {
    error = e
  }

  return { response, error }
}

export const getProjectFromDB = async (projectName) => {
  let response
  let error
  try {
    const snapshot = await projectsRef
      .where('name', '==', projectName)
      .limit(1)
      .get()
    response = snapshot.docs[0].data()
  } catch (e) {
    error = e
  }

  return { response, error }
}

export const getProject = (projectSlug) => {
  const { projects } = store._state.data.root

  const project = projects.find((item) => item.projectSlug === projectSlug)

  if (!project) {
    const errorMessage = `Project: "${projectSlug}" not found.`

    console.error(errorMessage)
    throw new Error(errorMessage)
  }

  const { configuration } = project

  return {
    ...project,
    configuration: {
      ...configuration,
      contractABI: JSON.parse(configuration.contractABI),
    },
  }
}

export const getAllContractConfigs = () => {
  const { projects } = store._state.data.root

  const contractConfigs = projects.map((item) => ({
    address: item.configuration.contractAddress,
    projectSlug: item.projectSlug,
  }))
  return contractConfigs
}

export const selectProjectContract = async (projectSlug, web3, dispatch) => {
  try {
    const project = getProject(projectSlug)
    dispatch('setSelectedProject', project)

    const { contractABI, contractAddress } = project.configuration

    let contractService = null

    switch (projectSlug) {
      case 'norte':
        contractService = new NorteContractService(web3)
        break
      case 'test-project':
        contractService = new TestProjectContractService(web3)
        break
      case 'palabras':
        contractService = new PalabrasContractService(web3)
        break
      case 'frases':
        contractService = new FrasesContractService(web3)
        break
      case 'base-gen-art':
        contractService = new BaseGenArtContractService(web3)
        break
      case 'escaleras':
        contractService = new EscalerasContractService(web3)
        break
      default:
        throw new Error(`Project: "${projectSlug}" not supported.`)
    }

    const contract = await contractService.getContract(
      contractABI,
      contractAddress
    )

    dispatch('setContract', contract)
  } catch (e) {
    console.error('An error occurred while selecting the project contract.', e)
    throw e
  }
}
