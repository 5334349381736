export default {
  setMintingStep({ commit }, step) {
    commit('SET_MINTING_STEP', step)
  },
  setTxHash({ commit }, txHash) {
    commit('SET_TX_HASH', txHash)
  },
  setMintedQty({ commit }, mintedQty) {
    commit('SET_MINTED_QTY', mintedQty)
  },
  setTxError({ commit }, txError) {
    commit('SET_TX_ERROR', txError)
  },
}
