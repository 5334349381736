import actions from './actions'
import mutations from './mutations'

const state = {
  featureFlags: [],
  featureFlagsExpirationDate: new Date(),
  projects: [],
  projectsExpirationDate: new Date(),
  selectedProject: null,
}

export default {
  namespaced: false,
  actions,
  mutations,
  state,
}
