export default {
  SET_ALL_COUNTS(state, payload) {
    state.allCounts = payload
  },

  SET_TOKEN_ATTRS_COUNTS(state, payload) {
    state.tokenAttrsCounts = payload
  },

  SET_PAGINATION_CURSOR(state, payload) {
    state.paginationCursor = payload
  },

  SET_SORTING_SELECTED(state, payload) {
    state.sortingSelected = payload
  },

  SET_TOKEN_ATTRS(state, payload) {
    state.tokenAttrs = payload
  },

  PUSH_TOKEN_ATTRS(state, payload) {
    state.tokenAttrs.push(payload)
  },

  REMOVE_TOKEN_ATTR(state, payload) {
    state.tokenAttrs = state.tokenAttrs.filter((attr) => attr.name != payload)
  },

  RESET_TOKEN_ATTRS(state) {
    state.tokenAttrs = []
  },

  RESET_TOKENS_DATA(state) {
    state.tokensData = []
    state.paginationCursor = null
  },

  PUSH_TOKEN_DATA(state, payload) {
    state.tokensData.push(...payload)
  },

  SET_TOKEN_DATA(state, payload) {
    state.tokensData = payload
  },
}
