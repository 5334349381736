import { ElMessage } from 'element-plus'
import * as configcat from 'configcat-js'
import { ElNotification } from 'element-plus'
import store from '../store/index'

let client = null
const notificationTime = process.env.VUE_APP_NOTIFICATION_DURATION

const getClient = () => {
  if (client) {
    return client
  }

  // To enable logging:
  // const logger = configcat.createConsoleLogger(3) // Setting log level to 3 (Info)
  // client = configcat.createClient(process.env.VUE_APP_CONFIGCAT_API_KEY, {
  //   logger,
  // })
  client = configcat.createClient(process.env.VUE_APP_CONFIGCAT_API_KEY)

  return client
}

export const copyToClipboard = async (text) => {
  await navigator.clipboard.writeText(text)

  ElMessage({
    message: 'Copied to Clipboard!',
    type: 'success',
  })
}

export const formatAddress = (address) => {
  const length = address.length
  return `${address.substring(0, 5)}...${address.substring(
    length - 6,
    length - 1
  )}`
}

export const isFeatureFlagEnabled = (featureflag) => {
  const { featureFlags } = store._state.data.root

  const featureFlag = featureFlags.find(
    (item) => item.settingKey === featureflag
  )

  return featureFlag ? featureFlag.settingValue : false
}

export const getAllFeatureFlags = async () => {
  return getClient().getAllValuesAsync()
}

export const selectNotification = async (e, notificationType = false) => {
  if (notificationType) {
    ElNotification({
      title: 'Success',
      message: 'Minting successful.',
      type: 'success',
      duration: notificationTime,
    })
  } else {
    if (e.code === 4001) {
      ElNotification({
        title: 'Error',
        message: 'Minting cancelled.',
        type: 'error',
        duration: notificationTime,
      })
    } else if (e.code === -32603) {
      ElNotification({
        title: 'Error',
        message: 'Error processing TX.',
        type: 'error',
        duration: notificationTime,
      })
    } else {
      ElNotification({
        title: 'Error',
        message: `Minting failed: ${e.message}`,
        type: 'error',
        duration: notificationTime,
      })
    }
  }
}
